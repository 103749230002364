<template>
<div>
    <div class="p-4 text-start" v-if="patientStore.formData.physio">
        <div class="row">
            <div class="col-12 col-xl-5 mb-3 mb-xl-0">
                <div class="text-primary fs-18px text-ellipsis mb-3">{{ langStore.lang.views.select_body_part }}</div>

                <div class="position-relative mb-3" v-click-outside="hideSearchDropdown"> 
                    <div class="gray-layer" :class="{'d-none' : physioStore.bodyParts.length > 0}"></div>
                    <div class="input-group input-group-lg shadow-custom input-border-radius overflow-hidden w-100">
                        <input type="text" class="form-control border-0" v-model="physioStore.searchKeyword" :placeholder="langStore.lang.views.search_exercise">
                        <span class="input-group-text bg-white border-0">
                            <img width="14" :src="getImageURL('search-icon.svg')">
                        </span>
                    </div>

                    <div class="overflow-y-auto-thin-scrollbar search-exercise-dropdown" v-if="physioStore.searchKeyword">
                        <div class="px-4" v-for="exercise in physioStore.filteredExercises">
                            <div class="d-flex justify-content-between align-items-center border-bottom py-3">
                                <div class="d-flex">
                                    <img :src="getImageURL('blue-exercise-icon.svg')" class="me-2">
                                    <div>
                                        <div class="text-dark-blue fs-12px lh-21px">{{ physioStore.translate(exercise.lang_name) }}</div>
                                        <div class="text-dark-blue fs-10px">{{ langStore.lang.views.body_parts[exercise.body_part] }}</div>
                                    </div>
                                </div>

                                <a href="javascript:;" class="ms-2" @click="showAddModal(exercise)">
                                    <img :src="getImageURL('plus-square.svg')">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <BodyPartSelector />
            </div>
            <div class="col-12 col-xl-7">
                <div class="d-flex flex-column h-100">
                    <div class="text-primary fs-18px text-ellipsis mb-3">{{ langStore.lang.views.choose_exercises }}
                    </div>

                    <div class="d-flex mb-3">
                        <div>
                            <a href="javascript:" @click="prev">
                                <img :src="getImageURL('physio-calendar-left.svg')">
                            </a>
                        </div>
                        <div class="mx-2 flex-fill d-flex justify-content-between">
                            <div 
                                class="p-2 text-dark-blue physio-week-selector text-decoration-none fs-14px text-nowrap cursor-pointer"
                                :class="{ 'active': week == currentWeek && selectedDaysOfWeek.length > 0, 'd-block': week >= activeWeeks[0] && week <= activeWeeks[1], 'd-none': week < activeWeeks[0] || week > activeWeeks[1] }"
                                v-for="week in Object.keys(patientStore.formData.physio).map(item => Number(item))" @click="currentWeek = week"
                            >
                                {{ langStore.lang.views.week }} {{ week }}
                            </div>
                        </div>
                        <div>
                            <a href="javascript:" @click="next">
                                <img :src="getImageURL('physio-calendar-right.svg')">
                            </a>
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-fill weekly-exercises-container">
                        <div class="mb-3">
                            <div class="dropdown position-relative w-fit-content">
                                <button
                                    class="btn btn-light text-dark-blue fw-bold dropdown-toggle physio-day-picker-shadow input-border-radius px-3 show"
                                    type="button" 
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="true"
                                    data-bs-auto-close="outside" 
                                    :disabled="physioStore.bodyParts.length == 0">
                                    <span class="fs-14px" v-if="physioStore.bodyParts.length > 0">{{ langStore.lang.views.day }} {{ selectedDaysOfWeek.join(', ') }}</span>
                                    <span class="fs-14px text-gray me-5" v-else>-</span>
                                </button>
                                <div class="dropdown-menu py-2 px-3 border-0 input-border-radius shadow w-100 min-w-auto">
                                    <div class="d-flex w-fit-content mb-2"
                                        v-for="day in Object.keys(patientStore.formData.physio[currentWeek])">
                                        <input class="form-check-input" type="checkbox" :id="'day' + day" :value="day" :checked="selectedDaysOfWeek.includes(day)" @change="exerciseDaySelected($event, day)">
                                        <label class="form-check-label d-block text-gray10 ms-3 fs-14px fw-500" :for="'day' + day">{{ day }}</label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="flex-fill position-relative">
                            <div class="position-xl-absolute h-100 w-100 overflow-y-auto-thin-scrollbar pe-2">
                                <div class="mb-3" v-for="(exercises, day) in patientStore.formData.physio[currentWeek]">
                                    <div v-if="Object.values(exercises).length > 0">
                                        <div class="fs-14px text-dark-blue fw-bold mb-3">{{ langStore.lang.views.day }} {{ day }}</div>
                                        <div class="mb-2" v-for="exercise in Object.values(exercises)">
                                            <div class="exercise-container d-flex p-1 position-relative">
                                                <div class="exercise-thumbnail-container me-2"
                                                    :style="{ backgroundImage: `url(${getImageURL(exercise.image)})` }">
                                                </div>
                                                <div class="flex-fill d-flex flex-column justify-content-between py-1">
                                                    <div class="w-100 d-flex justify-content-between align-items-start">
                                                        <div class="d-flex">
                                                            <img :src="getImageURL('purple-exercise-icon.svg')">
                                                            <div class="fs-14px text-primary">{{ physioStore.translate(exercise.lang_name) }}</div>
                                                        </div>
                                                        <div>
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input float-unset" type="checkbox"
                                                                    role="switch" :true-value="true" :false-value="false"
                                                                    v-model="exercise.enabled" :checked="exercise.enabled">
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex justify-content-between">
                                                            <div class="d-flex align-items-end">
                                                                <div class="d-flex me-3 align-items-center">
                                                                    <img :src="getImageURL('clock-fast-forward.svg')"
                                                                        width="18" class="me-2">
                                                                    <div class="fs-14px text-dark-blue text-nowrap">{{ langStore.lang.exercises.reps }}:
                                                                        <span id="1-1-5-reps">{{ exercise.reps }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex align-items-center"><img
                                                                        :src="getImageURL('clock.svg')" width="18"
                                                                        class="me-2">
                                                                    <div class="fs-14px text-dark-blue text-nowrap">
                                                                        {{ langStore.lang.exercises.sets }}:
                                                                        <span id="1-1-5-sets">{{ exercise.sets }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a href="javascript:;" class="edit-exercise-btn text-decoration-none px-2 d-flex" @click="showEditModal(exercise)">
                                                                    <img :src="getImageURL('pencil-02.svg')">
                                                                    <div class="ms-1">{{ langStore.lang.views.edit }}</div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExerciseModal />
    </div>
    <EditExerciseModal />
</div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useLangStore } from '../../stores/lang';
import { usePatientStore } from '../../stores/patient';
import { usePhysioStore } from '../../stores/physio';
import BodyPartSelector from '../physio/BodyPartSelector.vue';
import { getImageURL } from '../../utils/image_url_helper'
import EditExerciseModal from '../physio/EditExerciseModal.vue';
import AddExerciseModal from '../physio/AddExerciseModal.vue';

const props = defineProps(['lang', 'operationType', 'physio', 'week', 'exercises', 'exerciseDays', 'bodyParts'])
const langStore = useLangStore()
const patientStore = usePatientStore();
const physioStore = usePhysioStore();

if(props.exerciseDays){
    patientStore.exerciseDays = props.exerciseDays
}

if(props.exercises){
    physioStore.exercises = props.exercises
}

if(props.bodyParts){
    physioStore.bodyParts = props.bodyParts
}

langStore.lang = props.lang
patientStore.formData.physio = props.physio

const currentWeek = ref(props.week)
const activeWeeks = ref([])

const setDefaultBodyParts = () => {
    let parts = [];
    switch (props.operationType) {
        case 'ot_hip':
            parts = ['hip']
            break;
        case 'ot_knee':
            parts = ['knee']
            break;
        case 'ot_spine':
            parts = ['cervical_spine', 'lumbar_spine']
            break;
        case 'ot_fa':
            parts = ['foot', 'ankle']
    }
    physioStore.bodyParts = parts
}
if(physioStore.bodyParts.length == 0){
    setDefaultBodyParts()
}

if (!patientStore.formData.physio) {
    physioStore.getExercisesForBodyParts().then(() => {
        physioStore.generatePhysioPlan(patientStore.exerciseDays)
    })
}else{
    physioStore.synchronizePhysioPlan()
}

watch(() => physioStore.bodyParts, () => {
    physioStore.getExercisesForBodyParts().then(() => {
        physioStore.synchronizePhysioPlan()
    })
}, {deep: true})

const getSlideRange = () => {
    const weeksPerSlide = 4;

    const slideNumber = Math.ceil(currentWeek.value / weeksPerSlide);
    const startingWeek = (slideNumber - 1) * weeksPerSlide + 1;
    const endingWeek = slideNumber * weeksPerSlide;

    activeWeeks.value = [startingWeek, endingWeek];
}

getSlideRange()

const prev = () => {
    if (activeWeeks.value[0] == 1 || physioStore.bodyParts.length == 0) return;
    activeWeeks.value[0] -= 4
    activeWeeks.value[1] -= 4
}

const next = () => {
    if (activeWeeks.value[0] == 49 || physioStore.bodyParts.length == 0) return;
    activeWeeks.value[0] += 4
    activeWeeks.value[1] += 4
}

const selectedDaysOfWeek = computed(() => {
    return Object.entries(patientStore.formData.physio[currentWeek.value])
        .filter(([key, value]) => Object.keys(value).length > 0)
        .map(([key, value]) => key)
})

const exerciseDaySelected = (e, day) => {
    const carePlanColumn = patientStore.getCarePlanColumn();

    if(e.target.checked){
        patientStore.exerciseDays.push(Number(day))
    }else{
        const index = patientStore.exerciseDays.indexOf(Number(day))
        patientStore.exerciseDays.splice(index, 1)
    }

    if(patientStore.formData.activities){
        patientStore.formData.activities.schedule[carePlanColumn][day].assigned = e.target.checked
    }
    physioStore.synchronizePhysioPlan()
}

onMounted(() => {
    const el = document.getElementById('edit-exercise-modal')
    physioStore.initEditModal(el)
    el.addEventListener('hidden.bs.modal', () => {
        physioStore.resetFormData()
    })
})

const showEditModal = (exercise) => {
    physioStore.currentExercise = exercise
    physioStore.formData = {...exercise}
    if(!physioStore.formData.name){
        physioStore.formData.name = physioStore.translate(exercise.lang_name)
    }
    if(!physioStore.formData.desc){
        physioStore.formData.desc = physioStore.translate(exercise.lang_desc)
    }
    physioStore.editModal.show()
}

const showAddModal = (exercise) => {
    physioStore.currentExercise = exercise
    const el = document.getElementById('assign-exercise-modal')
    el.addEventListener('hidden.bs.modal', () => {
        physioStore.resetFormData()
    })
    physioStore.initAddModal(el)
    physioStore.addModal.show()
}

const hideSearchDropdown = () => {
    physioStore.searchKeyword = ''
}

defineExpose({
    currentWeek,
    selectedDaysOfWeek
})
</script>